<template>
  <main class="register-reseller w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section class="register-reseller__numbers">
      <span class="register-reseller__number register-reseller__number_active">1</span>
      <span class="register-reseller__number register-reseller__number_active">2</span>
      <span class="register-reseller__number register-reseller__number_active">3</span>
      <span class="register-reseller__number register-reseller__number_active">4</span>
    </section>
    <h1 class="title title__h1 register-reseller__title">{{ $t("REGISTER_RESELLER.TITLE4") }}</h1>
    <p class="text register-reseller__text">{{ $t("REGISTER_RESELLER.ENTER_FOLLOWING_DETAILS") }}</p>
    <section class="reseller-docs register-reseller__reseller-docs">
      <div class="reseller-docs__container">
        <div class="reseller-docs__wrapper">
          <div class="reseller-docs__item">
            <div class="reseller-docs__img"></div>
            <p class="text reseller-docs__text">{{ $t("REGISTER_RESELLER.AUTHORIZED_DEALER_CERTIFICATE") }}</p>
            <button class="button reseller-docs__button reseller-docs__delete">
              <svg
                class="reseller-docs__svg"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
              >
                <defs:style>
                  .reseller-docs__svg {
                    fill: #002036;
                  }
                </defs:style>
                <path
                  class="reseller-docs__svg"
                  d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                />
              </svg>
            </button>
          </div>

          <div class="reseller-docs__item">
            <div class="reseller-docs__img reseller-docs__img-caution"></div>
            <p class="text reseller-docs__text">{{ $t("COMMON.ID") }}</p>
            <button class="button reseller-docs__button reseller-docs__camera">
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20">
                <defs:style>
                  .reseller-docs__svg {
                    fill: #002036;
                  }
                </defs:style>
                <path
                  class="reseller-docs__svg"
                  d="M11.429,48.786a5.357,5.357,0,1,1,5.357-5.357A5.361,5.361,0,0,1,11.429,48.786Zm0-9.286a3.929,3.929,0,1,0,3.929,3.929A3.934,3.934,0,0,0,11.429,39.5ZM10,43.429A1.433,1.433,0,0,1,11.429,42a.714.714,0,0,0,0-1.429,2.86,2.86,0,0,0-2.857,2.857.714.714,0,1,0,1.429,0Zm4.478-10a.362.362,0,0,1,.335.232l.987,2.625h4.915a.716.716,0,0,1,.714.714V49.857a.716.716,0,0,1-.714.714H2.143a.716.716,0,0,1-.714-.714V37a.716.716,0,0,1,.714-.714h4.92l.9-2.393a.716.716,0,0,1,.67-.464h5.848m0-1.429H8.629a2.14,2.14,0,0,0-2,1.388l-.554,1.469H2.143A2.143,2.143,0,0,0,0,37V49.857A2.143,2.143,0,0,0,2.143,52H20.714a2.143,2.143,0,0,0,2.143-2.143V37a2.143,2.143,0,0,0-2.143-2.143H16.786l-.638-1.7A1.775,1.775,0,0,0,14.478,32Z"
                  transform="translate(0 -32)"
                />
              </svg>
              <input class="reseller-docs__upload-input" type="file" />
            </button>
          </div>

          <div class="reseller-docs__item">
            <div class="reseller-docs__img reseller-docs__img-caution"></div>
            <p class="text reseller-docs__text">{{ $t("REGISTER_RESELLER.COMMUNICATION_CONTRACT") }}</p>
            <button class="button reseller-docs__button reseller-docs__edit">
              <svg xmlns="http://www.w3.org/2000/svg" width="31" height="21" viewBox="0 0 31 21">
                <g id="signature" transform="translate(0.5 -95.482)">
                  <path
                    class="reseller-docs__svg"
                    data-name="Union 3"
                    d="M2.412,20A2.412,2.412,0,0,1,0,17.588V2.412A2.412,2.412,0,0,1,2.412,0H17.588A2.412,2.412,0,0,1,20,2.412V4.888l4.125-4.1a2.7,2.7,0,0,1,3.819,0l1.292,1.292a2.7,2.7,0,0,1,0,3.819l-8.27,8.3A8.7,8.7,0,0,1,20,15v2.585A2.412,2.412,0,0,1,17.588,20ZM1.669,2.412V17.588a.744.744,0,0,0,.743.744H17.588a.745.745,0,0,0,.744-.744V14.139l.018-.011a6.735,6.735,0,0,0,1.433-1.1l.128-.129.089-.09h0l6.035-6.067L23.281,3.993,20,7.257,18.35,8.9l-.018.018v-6.5a.744.744,0,0,0-.744-.743H2.412A.744.744,0,0,0,1.669,2.412ZM25.3,1.971,24.2,3.076l2.752,2.752,1.1-1.1a1.034,1.034,0,0,0,0-1.461L26.764,1.971a1.033,1.033,0,0,0-1.46,0ZM13.345,16.682a2.242,2.242,0,0,1-1.971-1.243.584.584,0,0,0-1.054-.018l-.4.8a.835.835,0,0,1-1.538-.109L7.507,13.48l-.554,1.663a2.25,2.25,0,0,1-2.136,1.539H4.171a.834.834,0,1,1,0-1.669h.647a.58.58,0,0,0,.552-.4l.95-2.849a1.252,1.252,0,0,1,2.375,0l.724,2.169a2.341,2.341,0,0,1,3.441.739.578.578,0,0,0,.488.327,8.233,8.233,0,0,1,1.625-4.953,9.375,9.375,0,0,1,.85-.99l2.511-2.5V8.915L17,10.242a6.729,6.729,0,0,0-1.984,4.771h0a6.721,6.721,0,0,0,3.319-.875v1.842a8.45,8.45,0,0,1-3.319.7Z"
                    transform="translate(0 95.982)"
                    stroke="rgba(0,0,0,0)"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                </g>
              </svg>
              <input class="reseller-docs__upload-input" type="file" />
            </button>
          </div>

          <div class="reseller-docs__item">
            <div class="reseller-docs__img"></div>
            <p class="text reseller-docs__text">{{ $t("REGISTER_RESELLER.PERSONAL_GUARANTEE") }}</p>
            <button class="button reseller-docs__button reseller-docs__delete">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <defs:style>
                  .reseller-docs__svg {
                    fill: #002036;
                  }
                </defs:style>
                <path
                  class="reseller-docs__svg"
                  d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                />
              </svg>
            </button>
          </div>

          <div class="reseller-docs__item">
            <div class="reseller-docs__img"></div>
            <p class="text reseller-docs__text">{{ $t("REGISTER_RESELLER.HAWK_APPROVAL") }}</p>
            <button class="button reseller-docs__button reseller-docs__delete">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <defs:style>
                  .reseller-docs__svg {
                    fill: #002036;
                  }
                </defs:style>
                <path
                  class="reseller-docs__svg"
                  d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                />
              </svg>
            </button>
          </div>

          <div class="reseller-docs__item">
            <div class="reseller-docs__img"></div>
            <p class="text reseller-docs__text">{{ $t("REGISTER_RESELLER.CHIC_PHOTO") }}</p>
            <button class="button reseller-docs__button reseller-docs__delete">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <defs:style>
                  .reseller-docs__svg {
                    fill: #002036;
                  }
                </defs:style>
                <path
                  class="reseller-docs__svg"
                  d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <button class="button reseller-docs__add">
          <div class="reseller-docs__plus"></div>{{ $t("REGISTER_RESELLER.ADD_A_DOCUMENT") }}
        </button>
      </div>

      <button class="button reseller-docs__confirm">{{ $t("REGISTER_RESELLER.FINISHED") }}</button>
    </section>

    <section class="reseller-docs__modal">
      <div class="reseller-docs__modal-container">
        <button class="button reseller-docs__modal-close">
          <img src="assets/img/close.svg" alt />
        </button>

        <h2 class="title title__h2 reseller-docs__modal-title">{{ $t("REGISTER_RESELLER.ADD_A_DOCUMENT") }}</h2>

        <div class="reseller-docs__document">
          <label class="label reseller-docs__label">{{ $t("REGISTER_RESELLER.DOCUMENT_TYPE") }}</label>
          <div class="reseller-docs__dropdown">
            <select class="reseller-docs__dropdown-select">
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
              <option value="4">Four</option>
            </select>
            <button class="button reseller-docs__dropdown-button">{{ $t("COMMON.ID") }}</button>
            <div class="reseller-docs__dropdown-list">
              <a class="link reseller-docs__dropdown-option" href="#" data-value="1">One</a>
              <a class="link reseller-docs__dropdown-option" href="#" data-value="2">Two</a>
              <a class="link reseller-docs__dropdown-option" href="#" data-value="3">Three</a>
              <a class="link reseller-docs__dropdown-option" href="#" data-value="4">Four</a>
            </div>
          </div>
        </div>

        <div class="reseller-docs__modal-wrapper">
          <svg
            class="reseller-docs__modal-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="20"
            viewBox="0 0 23 20"
          >
            <defs:style>
              .reseller-docs__modal-camera {
                fill: #ffffff;
              }
            </defs:style>
            <path
              class="reseller-docs__modal-camera"
              d="M11.429,48.786a5.357,5.357,0,1,1,5.357-5.357A5.361,5.361,0,0,1,11.429,48.786Zm0-9.286a3.929,3.929,0,1,0,3.929,3.929A3.934,3.934,0,0,0,11.429,39.5ZM10,43.429A1.433,1.433,0,0,1,11.429,42a.714.714,0,0,0,0-1.429,2.86,2.86,0,0,0-2.857,2.857.714.714,0,1,0,1.429,0Zm4.478-10a.362.362,0,0,1,.335.232l.987,2.625h4.915a.716.716,0,0,1,.714.714V49.857a.716.716,0,0,1-.714.714H2.143a.716.716,0,0,1-.714-.714V37a.716.716,0,0,1,.714-.714h4.92l.9-2.393a.716.716,0,0,1,.67-.464h5.848m0-1.429H8.629a2.14,2.14,0,0,0-2,1.388l-.554,1.469H2.143A2.143,2.143,0,0,0,0,37V49.857A2.143,2.143,0,0,0,2.143,52H20.714a2.143,2.143,0,0,0,2.143-2.143V37a2.143,2.143,0,0,0-2.143-2.143H16.786l-.638-1.7A1.775,1.775,0,0,0,14.478,32Z"
              transform="translate(0 -32)"
            />
          </svg>

          <input class="reseller-docs__modal-upload" type="file" />
        </div>

        <button class="button reseller-docs__modal-button">{{ $t("REGISTER_RESELLER.UPLOAD_DOCUMENT") }}</button>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "RegisterReseller4",
};
</script>
<style lang="scss" scoped>
.ltr-type {
  .reseller-docs__plus {
    margin-left: unset;
    margin-right: 16px;
  }
  .reseller-docs__text {
    margin-right: unset;
    margin-left: 20px;
  }
  .reseller-docs__button {
    margin-left: auto;
    margin-right: unset;
  }
}
</style>